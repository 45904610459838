<template>
   <div class="emb-aboutus-wrap">
		<emb-page-title
			heading="About Us"
			subHeading="Creative team and wide goals."
		>
		</emb-page-title>
		<div class="emb-about-content">
			<div class="about-page section-gap">
				<v-container grid-list-xl>
					<v-layout row wrap align-center>
						<v-flex xs12 sm12 md12 lg6 xl6 pr-5>
							<h3 class="font-weight-bold font-italic mb-6"> We delivering a business oriented niche solution with an integrated custom consensus algorithm within the blockchain architecture for a comprehensive solution.</h3>
							<p class="mb-6">Data is everywhere, effective use of data determines how a business functions Right from Goverment. Services to Healthcare to Manufacturing, efficient and effective use of data can be not only mean reduced efforts and costs but overall functional and business growth all round. Our aim at Chain Sense begins from expanding the awareness, spread and applications of Blockchain in our everyday lives to help reshape how we use and let others use our data.</p>
							<v-btn large class="accent mx-0">Read More</v-btn>
						</v-flex>
						<v-flex xs12 md12 lg6 xl6 text-lg-left text-center>
							<img alt="About us Figure" src="/static/images/about-us.jpg" width="700" height="700">
						</v-flex>
					</v-layout>
				</v-container>
			</div>
			<div class="about-info section-gap bg-grey">
				<v-container grid-list-xl py-0>
					<v-layout row wrap align-center mb-md-0 mb-6>
						<v-flex xl6 sm12  xs12 md6 lg6 text-md-left text-center>
							<img src="/static/images/our-mission.jpg" alt="mission" width="900" height="700">
						</v-flex>
						<v-flex xl6 xs12 md6 lg6 class="pl-12">
							<h2 class="font-weight-bold">
								Our Mission
							</h2>
							<h5 class="font-italic">nulla dolor dicta laborum unde molestias ab magni.Lorem ipsum dolor sit amet,
							consectetur adipisicing elit</h5>
							<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat quibusdam cum blanditiis voluptas,
							voluptates hic eius maxime dolorum saepe quae animi eveniet nulla dolor dicta laborum unde molestias ab
							magni.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat quibusdam cum blanditiis
							voluptas,
							voluptates hic eius maxime dolorum saepe quae animi eveniet nulla dolor dicta laborum unde molestias ab
							magni.</p>
						</v-flex>
					</v-layout>
					<v-layout row wrap align-center reverse>
						<v-flex xl6 xs12 md6 lg6>
							<img src="/static/images/our-vision.jpg" alt="vission" width="900" height="700">
						</v-flex>
						<v-flex xl6 xs12 md6 lg6 class="pr-12">
							<h2 class="font-bold">
								Our Vision
							</h2>
							<h5 class="font-italic">nulla dolor dicta laborum unde molestias ab magni.Lorem ipsum dolor sit amet,
								consectetur adipisicing elit</h5>
							<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat quibusdam cum blanditiis voluptas,
							voluptates hic eius maxime dolorum saepe quae animi eveniet nulla dolor dicta laborum unde molestias ab
							magni.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat quibusdam cum blanditiis
							voluptas,
							voluptates hic eius maxime dolorum saepe quae animi eveniet nulla dolor dicta laborum unde molestias ab
							magni.</p>
						</v-flex>
					</v-layout>
				</v-container>
			</div>
			<TestimonialV2 />
		</div>
   </div>
</template>

<script>
import { mapGetters } from "vuex";
import TestimonialV2 from "Components/Widgets/TestimonialV2";

export default {
  computed: {
    ...mapGetters(["rtlLayout"])
  },
  components: {
    TestimonialV2
  },
  data() {
    return {
      valid: false,
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      inputRules: {
        basictextRules: [v => !!v || "This field should not be empty"]
      },
      /**
       * Slick Options
       **/
      slickOptions: {
        autoplay: false,
        slidesToShow: 1,
        infinite: false,
        arrows: false,
        dots: false,
        slidesToScroll: 1,
        swipe: true,
        seed: 100,
        rtl: this.rtlLayout,
        responsive: [
          {
            breakpoint: 390
          }
        ]
      },
      /**
       *** Data
       **/
      reviews: [
        {
          img: "/static/images/user-1.jpg",
          name: "Albert Gomez",
          position: "Head, ABS Infos",
          desc:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autexpedita quasi voluptatibus tenetur nisi ad earum atque laboriosam accusamus. Doloremque sapiente aliquid quae. Animi ratione dolorum repellat amet nihil? Magnam?"
        },
        {
          img: "/static/images/user-2.jpg",
          name: "Tristan Cyrus",
          position: "Head, ABS Infos",
          desc:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autexpedita quasi voluptatibus tenetur nisi ad earum atque laboriosam accusamus. Doloremque sapiente aliquid quae. Animi ratione dolorum repellat amet nihil? Magnam?"
        },
        {
          img: "/static/images/user-3.jpg",
          name: "Kendrick Younes",
          position: "Head, ABS Infos",
          desc:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autexpedita quasi voluptatibus tenetur nisi ad earum atque laboriosam accusamus. Doloremque sapiente aliquid quae. Animi ratione dolorum repellat amet nihil? Magnam?"
        },
        {
          img: "/static/images/user-4.jpg",
          name: "Beljium Hugh",
          position: "Head, ABS Infos",
          desc:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autexpedita quasi voluptatibus tenetur nisi ad earum atque laboriosam accusamus. Doloremque sapiente aliquid quae. Animi ratione dolorum repellat amet nihil? Magnam?"
        }
      ],
      teamListing: null
    };
  },
  mounted() {
    this.getTeam();
  },
  methods: {
    
  }
};
</script>
